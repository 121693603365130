import React from 'react';
import moment from 'moment-timezone';
import 'moment/locale/zh-tw';
import p from '@mapbox/polyline';
import Typography from '@material-ui/core/Typography';

import { LOCALE, TIME_ZONE, genders } from '@silvergatedelivery/constants';
import TagChip from 'components/TagChip';
import { sortBy } from './sorting';

export const formatAddress = (inAddressObject = {}, options = {}) => {
  if (typeof inAddressObject === 'string') return inAddressObject;

  const { ignoreNote = false, includeZipCode = true, ignoreCountry = true } = options;
  const {
    country,
    county,
    district,
    street,
    zipCode,
    note,
  } = inAddressObject;
  if (!county) return null;
  const noteString = (!ignoreNote && note) ? `(${note})` : '';
  const zipCodeString = (includeZipCode && zipCode) ? `${zipCode} ` : '';
  const countryString = (country && !ignoreCountry) ? `${country} ` : '';
  return `${zipCodeString}${countryString}${county || ''}${district || ''}${street || ''}` + noteString;
};

export const formatAddressLatLng = (inAddressObject = {}) => {
  if (typeof inAddressObject === 'string') return inAddressObject;

  const {
    lat,
    lng,
  } = inAddressObject;
  if (!lat || !lng) return '';

  return `${lat}, ${lng}`;
};


export const formatBankAccount = (inBankAccount) => {
  if (typeof inBankAccount === 'string') return inBankAccount;
  const {
    name,
    number,
  } = inBankAccount || {};

  return `${name || ''} ${number || ''}`;
};

export const formatGender = (inGender) => {
  if (!inGender) return '';

  return genders.find(({ value }) => value === inGender).label;
};

export const formatAge = (inDatetime, base = Date.now()) => {
  if (!inDatetime) return 'N/A';
  const ageDifMs = base - new Date(inDatetime).getTime() - 86400000;
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const formatDatetime = (inDatetime, options = {}) => {
  if (!inDatetime) return '';

  const {
    year = true, day = true, weekday = true, hour = true, minute = true, second = false,
  } = options;

  return new Date(inDatetime).toLocaleString(LOCALE, {
    timeZone: TIME_ZONE,
    weekday: weekday ? 'long' : undefined,
    year: year ? 'numeric' : undefined,
    month: 'short',
    day: day ? 'numeric' : undefined,
    hour: hour ? '2-digit' : undefined,
    minute: minute ? '2-digit' : undefined,
    second: second ? '2-digit' : undefined,
    // hour12: false,
  });
};

export const formatTime = (inDatetime) => {
  if (!inDatetime) return '';

  return new Date(inDatetime).toLocaleString(LOCALE, {
    timeZone: TIME_ZONE,
    weekday: undefined,
    year: undefined,
    month: undefined,
    day: undefined,
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
};


export const formatDatetimeFromNow = (inDatetime) => {
  if (!inDatetime) return '';

  return moment(inDatetime).tz(TIME_ZONE).locale('zh-tw').fromNow();
};

export const formatEmergencyContact = (inEmergencyContact = {}) => {
  if (!inEmergencyContact) return null;
  const { name, relationship, phoneNumber, phoneNumber2 } = inEmergencyContact;
  if (!name) return null;
  return `${name} (${relationship}) ${phoneNumber} ${phoneNumber2 || ''}`;
};

export const formatCenter = (inBounds = {}, string = false) => {
  const { south, west, north, east } = inBounds;

  const center = {
    lat: (north + south) / 2,
    lng: (east + west) / 2,
  };

  return string ? `${center.lat},${center.lng}` : center;
};

export const formatPolyline = (inPolylineString, string = false) => {
  const coordinates = p.decode(inPolylineString);
  return coordinates.map((item) => {
    const [lat, lng] = item;
    return string ? `${lat},${lng}` : { lat, lng };
  });
};

export const getStartEndForDeliveryTime = (timestamp) => {
  // +/- 30 minutes
  const start = moment(timestamp).tz(TIME_ZONE).add(-30, 'minutes').toISOString();
  const end = moment(timestamp).tz(TIME_ZONE).add(30, 'minutes').toISOString();

  return { start, end };
};

export const formatDeliveryPeriod = (start, end) => {
  const startString = new Date(start).toLocaleString(LOCALE, {
    timeZone: TIME_ZONE,
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  const endString = new Date(end).toLocaleString(LOCALE, {
    timeZone: TIME_ZONE,
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${startString} - ${endString}`;
};

export const formatTags = (tags) => {
  if (!tags || !tags.items || tags.items.length === 0) {
    return '無';
  }
  return (
    <React.Fragment>
      {tags.items
        .map(({ tag }) => tag)
        .sort(sortBy('name'))
        .sort(sortBy('subcategory'))
        .sort(sortBy('category'))
        .map((tag, index)=>(
          <TagChip
            key={index}
            tag={tag}
          />
        ))}
    </React.Fragment>);
};

export const formatNote = (note) => {
  if (!note) return '';
  return (
    <React.Fragment>
      {note.split('\n').map((item, index)=>(
        <Typography
          key={index} style={{ margin: 0 }}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {item}
        </Typography>
      ))}
    </React.Fragment>);
};

export const formatBirthday = (birthday) => {
  if (!birthday) return '';
  const [year, month, day] = birthday.split('-');
  const ROCYear = parseInt(year) - 1911;
  return `${ROCYear}年${month}月${day}日`;
};

export const getDisadvantagedType = (disadvantagedTypesHistory) => {
  let type = '';
  if (disadvantagedTypesHistory) {
    const today = moment().format('YYYY-MM-DD');
    disadvantagedTypesHistory.sort(sortBy('approvedDate')).forEach(({ disadvantagedTypes, approvedDate }) => {
      if (approvedDate <= today) {
        type = disadvantagedTypes;
      } else {
        return;
      }
    });
  }
  return type;
};
