import moment from 'moment-timezone';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import { sortBy } from 'utilities/sorting';

export const getSplitNumberMappings = (value) => {
  const numberString = `${value}`.split('');

  return {
    十萬: numberString[numberString.length - 6] || '',
    萬: numberString[numberString.length - 5] || '',
    千: numberString[numberString.length - 4] || '',
    百: numberString[numberString.length - 3] || '',
    十: numberString[numberString.length - 2] || '',
    元: numberString[numberString.length - 1] || '',
  };
};

export const getMonthDates = (inMonth = 'YYYY-MM', format = 'DD dddd') => {
  const daysInMonth = moment(inMonth).daysInMonth();
  const arrDays = Array(daysInMonth).fill('').map((x, index) => {
    const d = moment(inMonth)
      .startOf('month')
      .add(index, 'days');
    let label = d.locale('zh-tw').format(format);
    if (format === 'DD dddd') {
      label = label.replace(' 星期', '(') + ')';
    }
    return {
      date: d.format('YYYY-MM-DD'),
      label,
    };
  });

  return arrDays;
};

export const convertDateToZhFormat = (inDate = 'YYYY-MM-DD') => {
  const [year, month, day] = inDate.split('-');

  return `${parseInt(year) - 1911}/${month}/${day}`;
};

export const getROCYear = (yearMonth) => {
  if (!yearMonth) return '';
  const [year] = yearMonth.split('-');
  return `${parseInt(year) - 1911}`;
};

function alphaToNum(alpha) {
  let i = 0;
  let num = 0;
  const len = alpha.length;

  for (; i < len; i++) {
    num = num * 26 + alpha.charCodeAt(i) - 0x40;
  }

  return num - 1;
}

function numToAlpha(num) {
  let alpha = '';

  for (; num >= 0; num = parseInt(num / 26, 10) - 1) {
    alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
  }

  return alpha;
}

export const buildColumnsArray = (range) => {
  let i;
  const res = [];
  const rangeNum = range.split(':').map((val) => {
    return alphaToNum(val.replace(/[0-9]/g, ''));
  });
  const start = rangeNum[0];
  const end = rangeNum[1] + 1;

  for (i = start; i < end; i++) {
    res.push(numToAlpha(i));
  }

  return res;
};

export const processShareOrder = (elders, orders) => {
  const linkGroupMappings = {};
  const log = [];
  elders.forEach((elder) => {
    if (elder.linkGroupName) {
      const elderOrders = orders.filter((({ elderId }) => elderId === elder.id));
      const data = linkGroupMappings[elder.linkGroupName] || {
        linkGroupName: elder.linkGroupName,
        elderIds: [],
        sharedOrders: [],
      };
      data.elderIds.push(elder.id);
      data.sharedOrders = [
        ...data.sharedOrders,
        ...elderOrders,
      ];
      linkGroupMappings[elder.linkGroupName] = data;
    }
  });

  Object.keys(linkGroupMappings).forEach((key) => {
    const { elderIds, sharedOrders } = linkGroupMappings[key];
    sharedOrders.forEach((order) => {
      let showLog = false;
      // 處理史家四人狀況，午餐一人，晚餐四人
      let numberOfMeals = elderIds.length - 1; // copy 到另外三人
      if (order.mealItems && order.mealItems.length === 1) { // 一種餐點，不同份數 (如果是多種餐點可能會錯掉，沒辦法產出正確報表)
        numberOfMeals = order.mealItems[0].quantity - 1; // 如果只有一份就不copy到其他人
      }
      const copiedTo = [];
      if (numberOfMeals < elderIds.length - 1) {
        showLog = true;
      }
      if (numberOfMeals > 0) {
        const elderIdsToShare = order.linkGroupElderIds && order.linkGroupElderIds.length !== 0 ? order.linkGroupElderIds : elderIds;
        elderIdsToShare.forEach((elderId) => {
          const matched = orders.find((x) => x.id === order.id && x.elderId === elderId);
          if (!matched && numberOfMeals > 0) {
            if (order.mealItems) {
              order.mealItems[0].quantity -= 1;
            }
            const copiedOrder = JSON.parse(JSON.stringify(order));
            copiedOrder.elderId = elderId;
            if (copiedOrder.mealItems) {
              copiedOrder.mealItems[0].quantity = 1;
            }
            orders.push(copiedOrder);
            numberOfMeals -= 1;
            copiedTo.push(elderId);
          }
        });
      }
      if (showLog) {
        const elderName = elders.find(({ id }) => id === order.elderId).name;
        const copiedToNames = copiedTo.map((elderId) => {
          const elder = elders.find(({ id }) => id === elderId);
          return elder.name;
        });
        console.log(`家庭代碼缺少餐點 ${key} ${order.id} ${order.date} ${order.mealSlot} 訂單從 ${elderName} 複製到 ${copiedToNames.join(', ')}`);
        log.push({
          '編號': order.id,
          '家庭代碼': key,
          '訂單送餐對象': elderName,
          '日期': order.date,
          '餐別': order.mealSlot === 'lunch' ? '午餐' : '晚餐',
          '餐點分配對象': copiedToNames.join(','),
        });
      }
    });
  });
  return log;
};

export const processDisadvantagedTypes = (selectedMonth, elders, inFromDate, inToDate) => {
  const fromDate = inFromDate || moment(selectedMonth).tz(TIME_ZONE).startOf('month').format('YYYY-MM-DD');
  const endDate = inToDate ?
    moment(inToDate).tz(TIME_ZONE).add(1, 'days').format('YYYY-MM-DD') :
    moment(selectedMonth).tz(TIME_ZONE).endOf('month').add(1, 'days').format('YYYY-MM-DD');
  elders.forEach((elder) => {
    const { disadvantagedTypesHistory, disadvantagedTypes } = elder;
    if (disadvantagedTypesHistory && disadvantagedTypesHistory.length !== 0) {
      const disadvantagedTypesSlot = [];
      disadvantagedTypesHistory.sort(sortBy('approvedDate')).forEach(({ approvedDate, disadvantagedTypes }, index) => {
        if (approvedDate === fromDate) {
          disadvantagedTypesSlot.push({
            start: approvedDate,
            type: disadvantagedTypes,
          });
        } else if (approvedDate > fromDate) {
          if (approvedDate <= endDate) {
            if (disadvantagedTypesSlot.length === 0) {
              disadvantagedTypesSlot.push({
                start: fromDate,
                end: approvedDate,
                type: index === 0 ? '一般' : disadvantagedTypesHistory[index - 1].disadvantagedTypes,
              });
              disadvantagedTypesSlot.push({
                start: approvedDate,
                type: disadvantagedTypes,
              });
            } else {
              disadvantagedTypesSlot[disadvantagedTypesSlot.length - 1].end = approvedDate;
              disadvantagedTypesSlot.push({
                start: approvedDate,
                type: disadvantagedTypes,
              });
            }
          } else { // approvedDate > endOfSelectedMonth
            if (disadvantagedTypesSlot.length === 0) {
              disadvantagedTypesSlot.push({
                start: fromDate,
                type: index === 0 ? '一般' : disadvantagedTypesHistory[index - 1].disadvantagedTypes,
              });
            }
          }
        }
      });
      if (disadvantagedTypesSlot.length === 0) {
        disadvantagedTypesSlot.push({
          start: fromDate,
          type: disadvantagedTypesHistory[disadvantagedTypesHistory.length - 1].disadvantagedTypes,
        });
      }
      disadvantagedTypesSlot[disadvantagedTypesSlot.length - 1].end = endDate;
      elder.disadvantagedTypesSlot = disadvantagedTypesSlot;
    } else if (!disadvantagedTypes) {
      elder.disadvantagedTypesSlot = [{
        start: fromDate,
        end: endDate,
        type: '一般',
      }];
    }
  });
};

export const unifyCellStyle = (worksheet, rowCount = 1, colRange = 'A:BB', style) => {
  const columnNames = buildColumnsArray(colRange);

  style = style || {
    alignment: {
      wrapText: true,
      vertical: 'top',
      // horizontal: 'center',
    },
  };

  Array(rowCount).fill('').map((x, index) => {
    columnNames.forEach((col) => {
      const name = `${col}${index+1}`;
      if (worksheet[name]) {
        worksheet[name].s = style;
      }
    });
  });

  return worksheet;
};

export const numberToChineseUpper = (number) => {
  const chineseNumbers = ['', '壹', '貳', '參', '肆', '伍', '陸', '柒', '捌', '玖'];
  const chineseUnits = ['', '拾', '佰', '仟'];
  const chineseBigUnits = ['', '萬', '億', '兆', '京'];

  const numStr = number.toString();
  let result = '';
  let groupCount = 0;
  let groupStr = '';

  for (let i = numStr.length - 1; i >= 0; i--) {
    const digit = parseInt(numStr[i]);
    if (digit !== 0) {
      groupStr = chineseNumbers[digit] + chineseUnits[groupCount % 4] + groupStr;
    } else {
      if (groupStr !== '') {
        groupStr = '零' + groupStr;
      }
    }

    if (groupCount % 4 === 3 || i === 0) {
      result = groupStr + chineseBigUnits[Math.floor(groupCount / 4)] + result;
      groupStr = '';
    }
    groupCount++;
  }

  if (result === '') {
    return '零';
  }

  return result;
};

export const getWeeksOfMonth = (month) => {
  const weeks = [];

  const firstDayOfMonth = moment(month, 'YYYY-MM').startOf('month');
  const lastDayOfMonth = moment(month, 'YYYY-MM').endOf('month');

  const startOfWeek = firstDayOfMonth.clone().startOf('week').add(1, 'days'); // Monday

  while (startOfWeek <= lastDayOfMonth) {
    const endOfWeek = startOfWeek.clone().add(6, 'days');
    weeks.push({
      start: startOfWeek.clone().format('YYYY-MM-DD'),
      end: endOfWeek.format('YYYY-MM-DD'),
    });
    startOfWeek.add(7, 'days');
  }

  return weeks;
};
