export const getEldersByClientByStatus = /* GraphQL */ `
  query GetEldersByClientByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEldersByClientByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        identificationCardId
        serviceCode
        sortOrder
        noteForDelivery
        noteForMeal
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        govQualifiedCount
        govQualifiedPrice
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        supervisorId
        linkGroupName
        fixedDeliveryStaffFee
        paymentBankIndex
        defaultDeliveryStaffId
        paymentMethod
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
