import {
  formatAddress,
  formatGender,
  formatBirthday,
  getDisadvantagedType,
} from 'utilities/format';

export const getFormFieldsValueMapping = (elder) => {
  if (!elder) return [];
  return [
    {
      fieldName: 'elderName',
      value: elder.name,
    },
    {
      fieldName: 'elderIdentificationCardId',
      value: elder.identificationCardId,
    },
    {
      fieldName: 'elderBirthday',
      value: formatBirthday(elder.birthday),
    },
    {
      fieldName: 'elderGender',
      value: formatGender(elder.gender),
    },
    {
      fieldName: 'elderPhoneNumber',
      value: elder.phoneNumber || '',
    },
    {
      fieldName: 'elderAddress',
      value: formatAddress(elder.address) || '',
    },
    {
      fieldName: 'elderEmergencyContactName',
      value: elder.emergencyContact?.name || '',
    },
    {
      fieldName: 'elderEmergencyContactRelationship',
      value: elder.emergencyContact?.relationship || '',
    },
    {
      fieldName: 'elderEmergencyContactPhoneNumber',
      value: elder.emergencyContact?.phoneNumber || '',
    },
    {
      fieldName: 'elderDisadvantagedType',
      value: getDisadvantagedType(elder.disadvantagedTypesHistory) || '',
    },
  ];
};
