import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormDialog from 'components/FormDialog';
import InteractivePdfViewer from 'views/FacilityAdmin/components/Forms/InteractivePdfViewer';
import PdfViewer from 'views/FacilityAdmin/components/Forms/PdfViewer';
import { request } from 'utilities/graph';
import { createElderForm, updateElderForm } from 'graphql/mutations';
import moment from 'moment-timezone';
import { download } from 'utilities/file';
import { Storage } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';
import ElderFormUploader from './ElderFormUploader';

import cache from 'utilities/cache';

export default function ElderFormFormEditButton({
  mode = 'edit',
  item,
  forms,
  onUpdate,
  elder,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [editItem, setEditItem] = useState({});

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handelFormUpload = () => {
    setOpenUpload(true);
  };

  const handleMenuClick = (id, fileS3Key, name) => () => {
    setEditItem({ form: { id, fileS3Key, name } });
    setOpenEdit(true);
    setAnchorEl(null);
  };

  const handleFormUpdate = () => {
    setEditItem(item);
    setOpenEdit(true);
  };

  const onSaveForm = async (blob) => {
    const now = moment().toISOString();
    if (editItem.id) {
      await Storage.put(editItem.fileS3Key, blob, {
        contentType: blob.type,
      });

      const username = cache.get('app:username');
      const input = {
        id: editItem.id,
        updatedAt: now,
        updatedBy: username,
      };
      const { data: { updateElderForm: data } } = await request(updateElderForm, { input });
      onUpdate && onUpdate(data);
    } else {
      const s3Key = `elders/${elder.id}/${now}/${editItem.name}`;
      await Storage.put(s3Key, blob, {
        contentType: blob.type,
      });

      const username = cache.get('app:username');
      const input = {
        elderId: elder.id,
        formId: editItem.form.id,
        name: editItem.form.name,
        fileS3Key: s3Key,
        createdAt: now,
        createdBy: username,
        updatedAt: now,
        updatedBy: username,
      };
      const { data: { createElderForm: data } } = await request(createElderForm, { input });
      onUpdate && onUpdate(data);
    }
    setEditItem({});
    setOpenEdit(false);
  };

  const openFilePreview = () => {
    setOpenPreview(true);
  };

  const fileDownload = async (fileS3Key, name) => {
    try {
      const url = await Storage.get(fileS3Key);
      const fileResponse = await fetch(url);
      if (!fileResponse.ok) {
        throw new Error('下載檔案失敗');
      }
      const blob = await fileResponse.blob();
      download(`${elder.name}-${name}.pdf`, blob);
    } catch (e) {
      console.error(e.message);
      toastr.error(e.message);
    }
  };

  return (
    <React.Fragment>
      {mode === 'edit' ?
        <>
          <Tooltip
            title={'下載'}
            placement="bottom"
          >
            <IconButton
              onClick={async () => {
                await fileDownload(item.fileS3Key, item.name);
              }}
            >
              {<CloudDownloadIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip
            title={'檢視'}
            placement="bottom"
          >
            <IconButton
              onClick={() => {
                openFilePreview();
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='編輯表單'>
            <span>
              <IconButton
                aria-label={mode}
                onClick={handleFormUpdate}
                disabled={forms.length === 0}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>:
        <>
          <Tooltip title='新增表單'>
            <span>
              <IconButton
                aria-label={mode}
                onClick={handleButtonClick}
                disabled={forms.length === 0}
              >
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title='上傳表單'>
            <span>
              <IconButton
                aria-label={mode}
                onClick={handelFormUpload}
              >
                <CloudUploadIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>
      }
      <Menu
        id="forms-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {forms.map(({ id, name, fileS3Key }) => (
          <MenuItem key={id} onClick={handleMenuClick(id, fileS3Key, name)}>{name}</MenuItem>
        ))}
      </Menu>
      {openPreview &&
        <FormDialog
          title={item.name || ''}
          openOnInit={true}
          onClose={() => {
            setOpenPreview(false);
          }}
          fullScreen={fullScreen}
        >
          <PdfViewer fileS3Key={item.fileS3Key} />
        </FormDialog>}
      {openEdit &&
        <FormDialog
          title={editItem?.name || editItem?.form?.name || ''}
          openOnInit={true}
          onClose={() => {
            setEditItem({});
            setOpenEdit(false);
          }}
          fullScreen={fullScreen}
          exitConfirm
        >
          <InteractivePdfViewer
            fileS3Key={editItem.id ? editItem.fileS3Key : editItem.form?.fileS3Key}
            onSaveForm={onSaveForm}
            elder={mode === 'edit' ? undefined: elder }
          />
        </FormDialog>}
      {openUpload &&
        <FormDialog
          title={'上傳表單'}
          openOnInit={true}
          onClose={() => {
            setOpenUpload(false);
          }}
          fullScreen={fullScreen}
          exitConfirm
        >
          <ElderFormUploader
            onUpdate={(data) => {
              onUpdate && onUpdate(data);
              setOpenUpload(false);
            }}
            elderId={elder.id}
          />
        </FormDialog>}
    </React.Fragment>
  );
}

ElderFormFormEditButton.propTypes = {
  mode: PropTypes.string,
  item: PropTypes.object,
  forms: PropTypes.array,
  onUpdate: PropTypes.func,
  elder: PropTypes.object,
};
