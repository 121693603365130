export default [
  {
    label: '衛福部支審系統',
    value: 'mohwPrsReport',
  },
  {
    label: '縣市核銷報表',
    value: 'countyPrsReport',
  },
  {
    label: '脆弱家庭核銷報表',
    value: 'vulnerableReport',
  },
  {
    label: 'Pandago 月訂單輸出',
    value: 'pandagoReport',
  },
  {
    label: 'Uber 月訂單輸出',
    value: 'uberReport',
  },
  {
    label: 'Lalamove 月訂單輸出',
    value: 'lalamoveReport',
  },
  {
    label: '餐廳製餐數報表輸出',
    value: 'restaurantReport',
  },
  {
    label: '餐廳製餐數報表輸出(按機構)',
    value: 'restaurantReportByOrg',
  },
  {
    label: '年度報表',
    value: 'yearlyReport',
  },
  {
    label: '餐飲服務費收據(弱勢長輩)',
    value: 'elderReceipt',
  },
  {
    label: '餐飲服務費收據(自費戶)',
    value: 'selfPayingReceipt',
  },
  {
    label: '送餐大使薪資表',
    value: 'deliveryStaffSalary',
  },
  {
    label: '會計對賬與簡訊表', // 彰化慈恩
    value: 'accountingSMS',
  },
  {
    label: '服務使用者分類統計表', // 彰化慈恩
    value: 'userType',
  },
];
