import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';

export default function LabelWithToolTip({ label, tooltip, color='#FFD700' }) {
  return (
    <span>
      {label}
      <Tooltip title={tooltip}>
        <Icon
          style={{
            fontSize: '12px',
            verticalAlign: 'middle',
            color,
            cursor: 'pointer',
          }}
        >
          <EmojiObjectsOutlinedIcon />
        </Icon>
      </Tooltip>
    </span>
  );
}

LabelWithToolTip.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  color: PropTypes.string,
};
