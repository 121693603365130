import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';
import { counties } from '@silvergatedelivery/constants';
import { asyncListAll, request } from 'utilities/graph';
import { getEldersByClientByStatus, getOrdersByClientByDate } from './queries';
import { getClient } from 'graphql/queries';
import {
  processShareOrder,
  processDisadvantagedTypes,
  buildColumnsArray,
  getWeeksOfMonth,
} from './CountyPrsReport/workbooks/helpers';
import JSZip from 'jszip';
import { download } from 'utilities/file';
import ExcelJS from 'exceljs';
import { toastr } from 'react-redux-toastr';
import LabelWithToolTip from './LabelWithToolTip';

const monthOptions = Array(10)
  .fill('')
  .map((x, index) => {
    return moment().add(-index, 'months').format('YYYY-MM');
  });

async function writeReport({
  county: inCounty,
  elders: allElders,
  orders: allOrders,
  selectedMonth: selectedYearMonth = 'YYYY-MM',
  client,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
}) {
  const elders = allElders.filter(({ address }) => address.county === inCounty);
  if (elders.length == 0) {
    toastr.error('沒有符合設定條件的送餐對象');
  }
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat([
      'ready',
      'readyForPickup',
      'delivering',
    ]);
  }
  let orders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    orders = orders.filter(
      ({ category }) => category === '送餐' || category === '送餐和物資',
    );
  }
  if (orders.length === 0) {
    toastr.error('沒有符合設定條件的訂單');
  }

  const zip = new JSZip();

  const workbook = new ExcelJS.Workbook();
  const [selectedYear, selectedMonth] = selectedYearMonth.split('-');
  const ROCYear = parseInt(selectedYear) - 1911;
  const firstDayOfMonth = moment(selectedYearMonth, 'YYYY-MM')
    .startOf('month')
    .format('YYYY-MM-DD');
  const lastDayOfMonth = moment(selectedYearMonth, 'YYYY-MM')
    .endOf('month')
    .format('YYYY-MM-DD');

  // 月統計
  const worksheetCountry = workbook.addWorksheet(
    `${ROCYear}${selectedMonth}依鄉鎮`,
  );
  getWorksheetClassifyByCountry(
    worksheetCountry,
    elders,
    orders,
    firstDayOfMonth,
    lastDayOfMonth,
  );

  const worksheetIdentity = workbook.addWorksheet(
    `${ROCYear}${selectedMonth}依身分別`,
  );
  getWorksheetClassifyByIdentity(
    worksheetIdentity,
    elders,
    orders,
    firstDayOfMonth,
    lastDayOfMonth,
  );

  const worksheetByLevel = workbook.addWorksheet(
    `${ROCYear}${selectedMonth}依等級`,
  );
  getWorksheetClassifyByLevel(
    worksheetByLevel,
    elders,
    orders,
    firstDayOfMonth,
    lastDayOfMonth,
  );

  const worksheetByGender = workbook.addWorksheet(
    `${ROCYear}${selectedMonth}依性別`,
  );
  getWorksheetClassifyByGender(
    worksheetByGender,
    elders,
    orders,
    firstDayOfMonth,
    lastDayOfMonth,
  );

  const worksheetByPay = workbook.addWorksheet(
    `${ROCYear}${selectedMonth}依補助_自購`,
  );
  getWorksheetClassifyByPay(
    worksheetByPay,
    elders,
    orders,
    firstDayOfMonth,
    lastDayOfMonth,
  );

  // 週統計
  const weeksOfMonth = getWeeksOfMonth(selectedYearMonth);

  const periodOrders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));

  weeksOfMonth.forEach(({ start, end }) => {
    const [startYear, startMonth, startDay] = start.split('-');
    const [, endMonth, endDay] = end.split('-');
    const ROCYear = parseInt(startYear) - 1911;
    const ordersInPeriod = periodOrders.filter(
      ({ date }) => date >= start && date <= end,
    );
    const worksheetCountry = workbook.addWorksheet(
      `${ROCYear}${startMonth}${startDay}-${endMonth}${endDay}依鄉鎮`,
    );
    getWorksheetClassifyByCountry(
      worksheetCountry,
      elders,
      ordersInPeriod,
      start,
      end,
    );

    const worksheetIdentity = workbook.addWorksheet(
      `${ROCYear}${startMonth}${startDay}-${endMonth}${endDay}依身分別`,
    );
    getWorksheetClassifyByIdentity(
      worksheetIdentity,
      elders,
      ordersInPeriod,
      start,
      end,
    );

    const worksheetByLevel = workbook.addWorksheet(
      `${ROCYear}${startMonth}${startDay}-${endMonth}${endDay}依等級`,
    );
    getWorksheetClassifyByLevel(
      worksheetByLevel,
      elders,
      ordersInPeriod,
      start,
      end,
    );

    const worksheetByGender = workbook.addWorksheet(
      `${ROCYear}${startMonth}${startDay}-${endMonth}${endDay}依性別`,
    );
    getWorksheetClassifyByGender(
      worksheetByGender,
      elders,
      ordersInPeriod,
      start,
      end,
    );

    const worksheetByPay = workbook.addWorksheet(
      `${ROCYear}${startMonth}${startDay}-${endMonth}${endDay}依補助_自購`,
    );
    getWorksheetClassifyByPay(
      worksheetByPay,
      elders,
      ordersInPeriod,
      start,
      end,
    );
  });

  try {
    if (workbook && workbook.worksheets && workbook.worksheets.length > 0) {
      const bufferDataByDistrict = await workbook.xlsx.writeBuffer();
      zip.file(
        `${ROCYear}${selectedMonth}服務使用者分類統計表.xlsx`,
        bufferDataByDistrict,
        { binary: true },
      );
    }
  } catch (e) {
    console.error(e);
  }

  const files = Object.keys(zip.files);
  if (files.length !== 0) {
    const zipContent = zip.generate({ type: 'blob' });
    download(`${ROCYear}${selectedMonth}服務使用者分類統計表.zip`, zipContent);
  }
}

function getWorksheetClassifyByCountry(
  worksheet,
  elders,
  orders = [],
  startDay,
  endDay,
) {
  // 每區elders 數量
  const periodOrders = orders.filter(
    ({ date }) => date >= startDay && date <= endDay,
  );
  const elderIds = [...new Set(periodOrders.map((item) => item.elderId))];
  const districtCounts = {};

  elderIds.forEach((elderId) => {
    const elderInfo = elders.find((elder) => elder.id === elderId);
    const district = elderInfo.address.district;

    if (!districtCounts[district]) {
      districtCounts[district] = 0;
    }

    districtCounts[district] += 1;
  });

  // 午晚餐數量
  const mealCounts = periodOrders.reduce((acc, order) => {
    const elderInfo = elders.find((elder) => elder.id === order.elderId);
    if (!elderInfo) {
      console.log(`can not find elder ${order.elderId} for order ${order.id}`);
      return;
    }

    const district = elderInfo.address.district;

    if (!acc[district]) {
      acc[district] = { lunch: 0, dinner: 0 };
    }

    const mealCount = order.mealItems.reduce(
      (sum, item) => sum + item.quantity,
      0,
    );
    acc[district][order.mealSlot] += mealCount;

    return acc;
  }, {});

  const fontName = 'Calibri';
  const range = 'A:Z';
  const columnArray = buildColumnsArray(range);

  const columnWidth = [10, 10, 10, 10, 10];
  columnWidth.forEach((_, index) => {
    worksheet.getColumn(columnArray[index]).width = columnWidth[index];
  });

  let rowIndex = 1;

  // 標題
  let cell;
  ['項目', '個案數', '全部數量', '午餐數量', '晚餐數量'].forEach(
    (header, index) => {
      cell = worksheet.getCell(`${columnArray[index]}${rowIndex}`);
      cell.value = header;
      cell.alignment = { horizontal: 'left', vertical: 'middle' };
      cell.font = { size: 11, name: fontName };
    },
  );
  rowIndex += 1;

  Object.entries(districtCounts).forEach(([district, count]) => {
    worksheet.getCell(`A${rowIndex}`).value = district;
    worksheet.getCell(`B${rowIndex}`).value = count;
    // 午晚餐數量
    const mealCount = mealCounts[district] || { lunch: 0, dinner: 0 };
    worksheet.getCell(`D${rowIndex}`).value = mealCount.lunch;
    worksheet.getCell(`E${rowIndex}`).value = mealCount.dinner;
    // 總數
    worksheet.getCell(`C${rowIndex}`).value = {
      formula: `D${rowIndex} + E${rowIndex}`,
    };

    rowIndex++;
  });

  return worksheet;
}

function getWorksheetClassifyByIdentity(
  worksheet,
  elders,
  orders = [],
  startDay,
  endDay,
) {
  // elders 身分別數量
  const periodOrders = orders.filter(
    ({ date }) => date >= startDay && date <= endDay,
  );

  const elderIds = [...new Set(periodOrders.map((item) => item.elderId))];
  const identityCounts = {};

  elderIds.forEach((elderId, _) => {
    const elderInfo = elders.find((elder) => elder.id === elderId);
    const { disadvantagedTypesSlot } = elderInfo;
    if (disadvantagedTypesSlot.length != 0) {
      disadvantagedTypesSlot.forEach(({ start: slotStart, end: slotEnd, type: identity }) => {
        if (slotStart <= endDay && startDay <= slotEnd) {
          if (!identityCounts[identity]) {
            identityCounts[identity] = 0;
          }
          identityCounts[identity] += 1;
        }
      });
    } else {
      if (!identityCounts['未設定']) {
        identityCounts['未設定'] = 0;
      }
      identityCounts['未設定'] += 1;
    }
  });

  // 午晚餐數量
  const mealCounts = periodOrders.reduce((acc, order) => {
    const elderId = order.elderId;
    const elderInfo = elders.find((elder) => elder.id === elderId);
    if (elderInfo.disadvantagedTypesSlot) {
      elderInfo.disadvantagedTypesSlot.forEach(({ start: slotStart, end: slotEnd, type: identity }) => {
        if (order.date >= slotStart && order.date <= slotEnd) {
          if (!acc[identity]) {
            acc[identity] = { lunch: 0, dinner: 0 };
          }

          const mealCount = order.mealItems.reduce(
            (sum, item) => sum + item.quantity,
            0,
          );
          acc[identity][order.mealSlot] += mealCount;
        }
      });
    }

    return acc;
  }, {});

  const fontName = 'Calibri';
  const range = 'A:Z';
  const columnArray = buildColumnsArray(range);

  const columnWidth = [10, 10, 10, 10, 10];
  columnWidth.forEach((_, index) => {
    worksheet.getColumn(columnArray[index]).width = columnWidth[index];
  });

  let rowIndex = 1;

  // 標題
  let cell;
  ['項目', '個案數', '全部數量', '午餐數量', '晚餐數量'].forEach(
    (header, index) => {
      cell = worksheet.getCell(`${columnArray[index]}${rowIndex}`);
      cell.value = header;
      cell.alignment = { horizontal: 'left', vertical: 'middle' };
      cell.font = { size: 11, name: fontName };
    },
  );
  rowIndex += 1;
  Object.entries(identityCounts).forEach(([identity, count]) => {
    worksheet.getCell(`A${rowIndex}`).value = identity;
    worksheet.getCell(`B${rowIndex}`).value = count;
    // 午晚餐數量
    const mealCount = mealCounts[identity] || { lunch: 0, dinner: 0 };
    worksheet.getCell(`D${rowIndex}`).value = mealCount.lunch;
    worksheet.getCell(`E${rowIndex}`).value = mealCount.dinner;
    // 總數
    worksheet.getCell(`C${rowIndex}`).value = {
      formula: `D${rowIndex} + E${rowIndex}`,
    };

    rowIndex++;
  });

  return worksheet;
}

function getWorksheetClassifyByGender(
  worksheet,
  elders,
  orders = [],
  startDay,
  endDay,
) {
  // elders 性別數量
  const periodOrders = orders.filter(
    ({ date }) => date >= startDay && date <= endDay,
  );
  const elderIds = [...new Set(periodOrders.map((item) => item.elderId))];
  const genderCounts = {};

  elderIds.forEach((elderId, _) => {
    const elderInfo = elders.find((elder) => elder.id === elderId);
    const gender = elderInfo.gender;

    if (!genderCounts[gender]) {
      genderCounts[gender] = 0;
    }

    genderCounts[gender] += 1;
  });

  // 午晚餐數量
  const mealCounts = periodOrders.reduce((acc, order) => {
    if (order.date <= endDay && order.date >= startDay) {
      const elderId = order.elderId;
      const elderInfo = elders.find((elder) => elder.id === elderId);
      if (!acc[elderInfo.gender]) {
        acc[elderInfo.gender] = { lunch: 0, dinner: 0 };
      }

      const mealCount = order.mealItems.reduce(
        (sum, item) => sum + item.quantity,
        0,
      );
      acc[elderInfo.gender][order.mealSlot] += mealCount;
    }
    return acc;
  }, {});

  const fontName = 'Calibri';
  const range = 'A:Z';
  const columnArray = buildColumnsArray(range);

  const columnWidth = [10, 10, 10, 10, 10];
  columnWidth.forEach((_, index) => {
    worksheet.getColumn(columnArray[index]).width = columnWidth[index];
  });

  let rowIndex = 1;

  // 標題
  let cell;
  ['項目', '個案數', '全部數量', '午餐數量', '晚餐數量'].forEach(
    (header, index) => {
      cell = worksheet.getCell(`${columnArray[index]}${rowIndex}`);
      cell.value = header;
      cell.alignment = { horizontal: 'left', vertical: 'middle' };
      cell.font = { size: 11, name: fontName };
    },
  );
  rowIndex += 1;

  const genderType = ['male', 'female'];

  genderType.forEach((val, _) => {
    const gender = val === 'male' ? '男' : '女';

    worksheet.getCell(`A${rowIndex}`).value = gender;
    worksheet.getCell(`B${rowIndex}`).value = genderCounts[val] || 0;
    // 午晚餐數量
    const mealCount = mealCounts[val] || { lunch: 0, dinner: 0 };

    worksheet.getCell(`D${rowIndex}`).value = mealCount.lunch;
    worksheet.getCell(`E${rowIndex}`).value = mealCount.dinner;
    // 總數
    worksheet.getCell(`C${rowIndex}`).value = {
      formula: `D${rowIndex} + E${rowIndex}`,
    };

    rowIndex++;
  });

  return worksheet;
}

function getWorksheetClassifyByLevel(
  worksheet,
  elders,
  orders = [],
  startDay,
  endDay,
) {
  // elders 級別數量
  const periodOrders = orders.filter(
    ({ date }) => date >= startDay && date <= endDay,
  );
  const elderIds = [...new Set(periodOrders.map((item) => item.elderId))];
  const levelCounts = {};

  elderIds.forEach((elderId, _) => {
    const elderInfo = elders.find((elder) => elder.id === elderId);
    const cmsLevel = elderInfo.cmsLevel;

    if (cmsLevel) {
      if (!levelCounts[cmsLevel]) {
        levelCounts[cmsLevel] = 0;
      }

      levelCounts[cmsLevel] += 1;
    } else {
      if (!levelCounts['未設定']) {
        levelCounts['未設定'] = 0;
      }

      levelCounts['未設定'] += 1;
    }
  });

  // 午晚餐數量
  const mealCounts = periodOrders.reduce((acc, order) => {
    const elderId = order.elderId;
    const elderInfo = elders.find((elder) => elder.id === elderId);
    const cmsLevel = elderInfo.cmsLevel || '未設定';

    if (cmsLevel) {
      if (order.date <= endDay && order.date >= startDay) {
        if (!acc[cmsLevel]) {
          acc[cmsLevel] = { lunch: 0, dinner: 0 };
        }

        const mealCount = order.mealItems.reduce(
          (sum, item) => sum + item.quantity,
          0,
        );
        acc[cmsLevel][order.mealSlot] += mealCount;
      }
      return acc;
    }
  }, {});

  const fontName = 'Calibri';
  const range = 'A:Z';
  const columnArray = buildColumnsArray(range);

  const columnWidth = [10, 10, 10, 10, 10];
  columnWidth.forEach((_, index) => {
    worksheet.getColumn(columnArray[index]).width = columnWidth[index];
  });

  let rowIndex = 1;

  // 標題
  let cell;
  ['項目', '個案數', '全部數量', '午餐數量', '晚餐數量'].forEach(
    (header, index) => {
      cell = worksheet.getCell(`${columnArray[index]}${rowIndex}`);
      cell.value = header;
      cell.alignment = { horizontal: 'left', vertical: 'middle' };
      cell.font = { size: 11, name: fontName };
    },
  );
  rowIndex += 1;

  const cmsLevels = [
    '1a級',
    '1b級',
    '1級',
    '2級',
    '3級',
    '4級',
    '5級',
    '6級',
    '7級',
    '8級',
    '未設定',
  ];

  cmsLevels.forEach((cms, _) => {
    const cmsCellName = cms === '未設定' ? '未設定' : '第' + cms;

    worksheet.getCell(`A${rowIndex}`).value = cmsCellName;
    worksheet.getCell(`B${rowIndex}`).value = levelCounts[cms] || 0;
    // 午晚餐數量
    const mealCount = mealCounts[cms] || { lunch: 0, dinner: 0 };
    worksheet.getCell(`D${rowIndex}`).value = mealCount.lunch;
    worksheet.getCell(`E${rowIndex}`).value = mealCount.dinner;
    // 總數
    worksheet.getCell(`C${rowIndex}`).value = {
      formula: `D${rowIndex} + E${rowIndex}`,
    };

    rowIndex++;
  });

  return worksheet;
}

function getWorksheetClassifyByPay(
  worksheet,
  elders,
  orders = [],
  startDay,
  endDay,
) {
  // elders 補助自費數量
  const payType = ['全補助個案', '全自購個案'];
  const periodOrder = orders.filter(
    ({ date }) => date >= startDay && date <= endDay,
  );
  const elderIds = [...new Set(periodOrder.map((item) => item.elderId))];
  const payCounts = {};

  elderIds.forEach((elderId, _) => {
    const elderInfo = elders.find((elder) => elder.id === elderId);
    const pay = elderInfo.isDisadvantaged;

    if (!payCounts[pay]) {
      payCounts[pay] = 0;
    }

    payCounts[pay] += 1;
  });

  // 午晚餐數量
  const mealCounts = orders.reduce((acc, order) => {
    const elderId = order.elderId;
    const elderInfo = elders.find((elder) => elder.id === elderId);
    const isDisadvantaged = elderInfo.isDisadvantaged;

    if (order.date <= endDay && order.date >= startDay) {
      if (!acc[isDisadvantaged]) {
        acc[isDisadvantaged] = { lunch: 0, dinner: 0 };
      }

      const mealCount = order.mealItems.reduce(
        (sum, item) => sum + item.quantity,
        0,
      );
      acc[isDisadvantaged][order.mealSlot] += mealCount;
    }

    return acc;
  }, {});

  const fontName = 'Calibri';
  const range = 'A:Z';
  const columnArray = buildColumnsArray(range);

  const columnWidth = [10, 10, 10, 10, 10];
  columnWidth.forEach((_, index) => {
    worksheet.getColumn(columnArray[index]).width = columnWidth[index];
  });

  let rowIndex = 1;

  // 標題
  let cell;
  ['項目', '個案數', '全部數量', '午餐數量', '晚餐數量'].forEach(
    (header, index) => {
      cell = worksheet.getCell(`${columnArray[index]}${rowIndex}`);
      cell.value = header;
      cell.alignment = { horizontal: 'left', vertical: 'middle' };
      cell.font = { size: 11, name: fontName };
    },
  );
  rowIndex += 1;

  payType.forEach((payType, _) => {
    const paymentMethod = payType === '全補助個案' ? 1 : 0;

    worksheet.getCell(`A${rowIndex}`).value = payType;
    worksheet.getCell(`B${rowIndex}`).value = payCounts[paymentMethod] || 0;
    // 午晚餐數量
    const mealCount = mealCounts[paymentMethod] || { lunch: 0, dinner: 0 };

    worksheet.getCell(`D${rowIndex}`).value = mealCount.lunch;
    worksheet.getCell(`E${rowIndex}`).value = mealCount.dinner;
    // 總數
    worksheet.getCell(`C${rowIndex}`).value = {
      formula: `D${rowIndex} + E${rowIndex}`,
    };

    rowIndex++;
  });

  return worksheet;
}

export default function UserTypeReport({ clientOptions }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0]);
  const [selectedCounty, setSelectedCounty] = useState(counties[0]);
  const [selectedClientId, setSelectedClientId] = useState();
  const [includeInactiveElders, setIncludeInactiveElders] = useState(false);
  const [includeNonDisElders, setIncludeNonDisElders] = useState(false);
  const [includeUndeliveredOrders, setIncludeUndeliveredOrders] =
    useState(false);
  const [excludeNonFoodDeliveryOrders, setExcludeNonFoodDeliveryOrders] =
    useState(false);

  const collectData = async () => {
    const weeksOfMonth = getWeeksOfMonth(selectedMonth);
    const fromDate = weeksOfMonth[0].start;
    const toDate = weeksOfMonth[weeksOfMonth.length - 1].end;
    const period = `${fromDate}__${toDate}`;
    const { id: clientId } = clientOptions.find(
      ({ id }) => id === selectedClientId,
    );

    global.logger.debug({
      selectedClientId,
      period,
      selectedMonth,
      fromDate,
      toDate,
    });

    const [
      elders,
      orders,
      {
        data: { getClient: client },
      },
    ] = await Promise.all([
      asyncListAll(getEldersByClientByStatus, {
        clientId,
        status: includeInactiveElders ? undefined : { eq: '使用中' },
        filter: includeNonDisElders ?
          undefined :
          {
            isDisadvantaged: {
              eq: 1,
            },
          },
        limit: 1000,
      }),
      asyncListAll(getOrdersByClientByDate, {
        clientId,
        date: {
          between: [fromDate, toDate],
        },
        limit: 1000,
      }),
      request(getClient, { id: clientId }),
    ]);

    processShareOrder(elders, orders);
    processDisadvantagedTypes(selectedMonth, elders, fromDate, toDate);

    writeReport({
      county: selectedCounty,
      elders,
      orders,
      selectedMonth,
      client,
      includeUndeliveredOrders,
      excludeNonFoodDeliveryOrders,
    });
  };

  const triggerDownload = async () => {
    try {
      setIsLoading(true);
      await collectData();
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCounty = (client) => {
    if (client && client.county) {
      const targetCounty = counties.find((county) => county === client.county);
      if (targetCounty) {
        setSelectedCounty(targetCounty);
      }
    }
  };

  useEffect(() => {
    if (clientOptions[0]) {
      setSelectedClientId(clientOptions[0].id);
      updateCounty(clientOptions[0]);
    }
  }, [clientOptions]);

  return (
    <div>
      <h3>服務使用者分類統計</h3>
      <div>
        <FormControl style={{ minWidth: 360, marginRight: 16 }}>
          <AutoSelect
            id='selected-restaurant'
            options={clientOptions.map(({ name }) => name)}
            values={clientOptions.map(({ id }) => id)}
            required={true}
            value={selectedClientId}
            label='機構'
            onChange={(value) => {
              setSelectedClientId(value);
              if (value) {
                const client = clientOptions.find(({ id }) => id === value);
                updateCounty(client);
              }
            }}
          />
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id='selected-month'>月份</InputLabel>
          <Select
            labelId='selected-month-label'
            id='selected-month'
            value={selectedMonth}
            onChange={(event) => setSelectedMonth(event.target.value)}
            disabled={isLoading}
          >
            {monthOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id='selected-county'>縣市</InputLabel>
          <Select
            labelId='selected-county-label'
            id='selected-county'
            value={selectedCounty}
            onChange={(event) => setSelectedCounty(event.target.value)}
            disabled={isLoading}
          >
            {counties.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeNonDisElders}
              name={'includeNonDisElders'}
              value={includeNonDisElders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeNonDisElders(e.target.checked);
              }}
            />
          }
          label={
            <LabelWithToolTip
              label='包含自費戶'
              tooltip='若送餐對象設定中 "弱勢長輩(非自費戶)" 選擇 "否" 則為自費戶'
            />
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={includeInactiveElders}
              name={'includeInactiveElders'}
              value={includeInactiveElders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeInactiveElders(e.target.checked);
              }}
            />
          }
          label={'包含已停用的個案'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={includeUndeliveredOrders}
              name={'includeUndeliveredOrders'}
              value={includeUndeliveredOrders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeUndeliveredOrders(e.target.checked);
              }}
            />
          }
          label={'包含未送達的訂單'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={excludeNonFoodDeliveryOrders}
              name={'excludeNonFoodDeliveryOrders'}
              value={excludeNonFoodDeliveryOrders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setExcludeNonFoodDeliveryOrders(e.target.checked);
              }}
            />
          }
          label={'排除非送餐的訂單'}
        />
      </div>

      <Button
        variant='outlined'
        onClick={triggerDownload}
        disabled={!selectedClientId || isLoading}
        style={{ marginTop: 16 }}
      >
        下載
        {isLoading && (
          <CircularProgress
            color='primary'
            size={16}
            style={{ marginLeft: 8 }}
          />
        )}
      </Button>
    </div>
  );
}

UserTypeReport.propTypes = {
  clientOptions: PropTypes.array,
};
