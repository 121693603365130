import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FormDialog from 'components/FormDialog';
import DataTable from 'components/Table/DataTable';
import { getFormsByClientByStatus } from 'graphql/queries';
import FormEditButton from './FormEditButton';
import cache from 'utilities/cache';
import { download } from 'utilities/file';
import { Storage } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useCache } from 'CacheProvider';
import { asyncListAll } from 'utilities/graph';
import { PDFDocument } from 'pdf-lib';
import fontkit from 'pdf-fontkit'; // https://github.com/Hopding/pdf-lib/issues/494#issuecomment-695818400
import PdfViewer from './PdfViewer';
import { getEldersByClientByStatus } from './queries';
import { getFormFieldsValueMapping } from './utils';

export default function FormTable({
  title = '表單',
  description,
  ...props
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFileS3Key, setSelectedFileS3Key] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isAdmin } = useCache();

  const clientName = cache.get('app:name');
  const clientId = cache.get('app:facilityId');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fileDownload = async (fileS3Key, name) => {
    try {
      const url = await Storage.get(fileS3Key);
      const fileResponse = await fetch(url);
      if (!fileResponse.ok) {
        throw new Error('下載檔案失敗');
      }
      const blob = await fileResponse.blob();
      download(`${name}.pdf`, blob);
    } catch (e) {
      console.error(e.message);
      toastr.error(e.message);
    }
  };

  const fileDownloadWithElders = async (fileS3Key, name, isDisadvantaged='true') => {
    setIsLoading(true);
    let fontBytes;
    let allElders;
    try {
      const [
        elders,
        response,
      ] = await Promise.all([
        asyncListAll(getEldersByClientByStatus, {
          clientId,
          status: { eq: '使用中' },
          filter: { isDisadvantaged: { eq: isDisadvantaged ? 1 : 0 } },
          limit: 1000,
        }),
        fetch('/fonts/NotoSansTC-Regular.ttf'),
      ]);
      if (!response.ok) {
        throw new Error(`Failed to fetch font: ${response.status} ${response.statusText}`);
      }
      fontBytes = await response.arrayBuffer();
      allElders = elders;
    } catch (e) {
      console.error(e);
      toastr.error('檔案下載失敗，請檢查網路連線後再重新嘗試');
      setIsLoading(false);
      return;
    }

    try {
      const fileUrl = await Storage.get(fileS3Key);
      const existingPdfBytes = await fetch(fileUrl).then((res) => res.arrayBuffer());
      const mergedPdfDoc = await PDFDocument.create();

      await Promise.all(allElders.sort((a, b) => a.name > b.name ? 1 : -1).map(async (elder) => {
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const form = pdfDoc.getForm();
        pdfDoc.registerFontkit(fontkit);
        const font = await pdfDoc.embedFont(fontBytes, { subset: true });
        // https://github.com/Hopding/pdf-lib/issues/1152  workaround:  WinAnsi cannot encode....
        const rawUpdateFieldAppearances = form.updateFieldAppearances.bind(form);
        form.updateFieldAppearances = function() {
          return rawUpdateFieldAppearances(font);
        };

        const formFieldsValueMapping = getFormFieldsValueMapping(elder);
        formFieldsValueMapping.forEach(({ fieldName, value }) => {
          try {
            const field = form.getTextField(fieldName);
            if (field) {
              field.setText(value || '');
            }
          } catch (e) {
            // An error will be thrown if no field exists with the provided name, or if the field exists but is not a text field.
            // console.log(e);
          }
        });

        const pdfBytes = await pdfDoc.save();
        const filledPdfDoc = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdfDoc.copyPages(filledPdfDoc, filledPdfDoc.getPageIndices());
        copiedPages.forEach((page) => mergedPdfDoc.addPage(page));
      }));

      const pdfBytes2 = await mergedPdfDoc.save();

      const blob = new Blob([pdfBytes2], { type: 'application/pdf' });
      download(`${name}.pdf`, blob);
    } catch (e) {
      console.error(e.message);
      toastr.error(e.message);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
  };

  const openFilePreview = (fileS3Key, name) => {
    setSelectedFileName(name);
    setSelectedFileS3Key(fileS3Key);
    setOpenPreview(true);
  };

  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'name',
      label: '表單名稱',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'fileS3Key',
      label: 'fileS3Key',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
      },
    },
    {
      name: 'category',
      label: '類別',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'subcategory',
      label: '子類別',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'note',
      label: '備註',
      options: {
        display: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'fileS3Key',
      label: ' ',
      options: {
        display: 'always',
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender(fileS3Key, tableMeta) {
          const name = tableMeta.rowData[1];
          return (
            <Box display="flex" flexDirection="row" alignItems="top">
              <Tooltip
                title={'下載空白表單'}
                placement="bottom"
              >
                <Box display="flex" flexDirection="column" alignItems="center" p={1}>
                  <IconButton
                    onClick={async () => {
                      await fileDownload(fileS3Key, name);
                    }}
                    disabled={isLoading}
                  >
                    {<CloudDownloadIcon />}
                  </IconButton>
                  <Typography variant='caption'>空白表單</Typography>
                </Box>
              </Tooltip>
              <Tooltip
                title={'下載預填弱勢長輩資料的表單'}
                placement="bottom"
              >
                <Box display="flex" flexDirection="column" alignItems="center" p={1}>
                  <IconButton
                    onClick={async () => {
                      await fileDownloadWithElders(fileS3Key, name);
                    }}
                    disabled={isLoading}
                  >
                    {<CloudDownloadIcon />}
                  </IconButton>
                  <Typography variant='caption'>弱勢長輩</Typography>
                </Box>
              </Tooltip>
              <Tooltip
                title={'下載預填自費戶資料的表單'}
                placement="bottom"
              >
                <Box display="flex" flexDirection="column" alignItems="center" p={1}>
                  <IconButton
                    onClick={async () => {
                      await fileDownloadWithElders(fileS3Key, name, false);
                    }}
                    disabled={isLoading}
                  >
                    {<CloudDownloadIcon />}
                  </IconButton>
                  <Typography variant='caption'>自費戶</Typography>
                </Box>
              </Tooltip>
              <Tooltip
                title={'檢視表單'}
                placement="bottom"
              >
                <Box display="flex" flexDirection="column" alignItems="center" p={1}>
                  <IconButton
                    onClick={() => {
                      openFilePreview(fileS3Key, name);
                    }}
                    disabled={isLoading}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <Typography variant='caption'>檢視</Typography>
                </Box>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  useEffect(() => {
    setIndexes([
      {
        name: 'getFormsByClientByStatus',
        label: '按狀態查詢',
        partitionKey: 'clientId',
        sortKey: 'status',
        fields: [{
          label: '機構',
          key: 'clientId',
          type: 'select',
          options: [{ value: clientId, label: clientName }],
        }, {
          label: '狀態',
          key: 'status',
          type: 'select',
          options: ['使用中', '已停用'].map((item) => {
            return { value: item, label: item };
          }),
        }],
        operation: getFormsByClientByStatus,
      },
    ]);
    setQueryParams({
      clientId,
      status: '使用中',
    });
  }, []);


  if (!indexes || !queryParams) return null;

  return (
    <React.Fragment>
      <DataTable
        indexes={indexes}
        queryDefaultParams={queryParams}
        title={title}
        description={description}
        columns={columns}
        editButton={isAdmin ? FormEditButton : undefined}
        {...props}
      />
      {openPreview &&
        <FormDialog
          title={selectedFileName}
          openOnInit={true}
          onClose={() => {
            setSelectedFileName('');
            setSelectedFileS3Key('');
            setOpenPreview(false);
          }}
          fullScreen={fullScreen}
        >
          <PdfViewer fileS3Key={selectedFileS3Key} />
        </FormDialog>}
    </React.Fragment>);
}

FormTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
